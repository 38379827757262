/* 320px */

.detalhe-lateral,
.detalhe-interna{ display: none; }

.botao-padrao{ margin-bottom: 30px; }

.item-galeria{
  text-align: center;
  margin-bottom: 25px; 
  a{
    img{
      display: inline-block;
    }
  }
}

.box-galeria{ padding-bottom: 0; }

.logo-footer{ text-align: center; margin-bottom: 30px; }
.infos-de-contato{ text-align: center;
  .ms {
    text-align: inherit;
  }
 }

.clique-menu{
  color: $branco;
  width: 50px;
  height: 50px;
  border: $branco solid 3px;
  @include radius(50%);
  text-align: center;
  line-height: 45px;
  font-size: 20px;
  display: inline-block;

}

.menu-responsivo{
  display: none;
  position: absolute;
  padding-top: 20px;
  padding-bottom: 50px;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 9000;
  background: rgba(255,69,140, 0.8);

  .ms{
    text-align: center;
  }
}

.mobile-menu{
  font-family: $mont;
  text-transform: uppercase;
  font-size: 700;
  color: $branco;
  margin-bottom: 30px;
  li{
    margin-bottom: 10px;
    a{
      font-size: 18px;
    }
  }
  .buy-now { 
    margin: 30px 0;
    a{
      border-radius: 50px;
      color: rgba(255,69,140, 1);
      background-color: white;
      padding: 15px;
      
    }
  }
}

.fechar-menu{
  color: $branco;
  margin-bottom: 20px;
  font-size: 30px;
}

.detalhe-lateral-mobile{
  background: url(../../assets/img/detalhe-menor.png) left top no-repeat;
  position: absolute;
  width: 587px;
  height: 290px;
  top: 0;
  left: -340px;
  z-index: 1;
}

.box-galeria{ padding-top: 30px; }

@media screen and (min-width: 540px) {
  .detalhe-lateral-mobile{ left: -225px; }
}

@media screen and (min-width: 768px) {
  .detalhe-lateral-mobile{ left: -265px; }
} 

@media screen and (min-width: 992px) {
  .detalhe-lateral-mobile{ left: -280px; }
  .box-galeria{ padding-top: 85px; }
}

@media screen and (min-width: 1200px) {

  .detalhe-lateral-mobile{ display: none; }

  .detalhe-lateral,
  .detalhe-interna{ display: block; }

  .detalhe-lateral{ left: -340px; }
  .detalhe-interna{ left: -245px; }

  .item-galeria{ margin-bottom: 0px; }
  .botao-padrao{ margin-bottom: 0px; }
  .box-galeria{ padding-bottom: 85px; }

  .logo-footer{ text-align: left; margin-bottom: 0px; }
  .infos-de-contato{ text-align: left; .ms {
    text-align: inherit;
  }}
}

@media screen and (min-width: 1400px) {

  .detalhe-lateral{ left: -255px; }
  .detalhe-interna{ left: -145px; }

}

@media screen and (min-width: 1600px) {

  .detalhe-lateral{ left: -165px; }
  .detalhe-interna{ left: -50px; }


}

@media screen and (min-width: 1700px) {

  .detalhe-lateral{ left: -115px; }
  .detalhe-interna{ left: 0px; }

}

@media screen and (min-width: 1800px) {

  .detalhe-lateral{ left: 0px; }
  .detalhe-interna{ left: 100px; }

}