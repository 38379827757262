.call-to-form {
  margin: 30px 0;
  color: #36bdd0;
  font-weight: 700;
  font-style: italic;
}

.contact {
  input {
    font-style: italic;
    padding: 15px;
    border-radius: 12px; 
    box-shadow: none;
    border: 1px solid #999999;
    margin: 5px 5px;
  }
  
  textarea {
    border-radius: 12px;
    resize: none;
    height: 100%;
    width: 100%;
    padding: 15px;
    margin: 5px;
    font-style: italic;
  }

  .form-cols {
    display: flex;
    justify-content: space-around;
    align-items: stretch;
    flex-direction: row;

    & > * {
      width: 50%;
    }

    .inputs {
      display: flex;
      flex-direction: column;
    }
  }
  
  .sent {
    font-weight: 700;
    color: white;
    background-color: #36bdd0;
    border: none;
    border-radius: 22px;
    padding: 12px 50px;
    margin-top: 15px;
  }
}

@media (max-width: 475px){
  .contact {
    textarea, input {
      margin: 5px 0;
    }
    .form-cols {
      flex-direction: column;
      & > * {
        width: 100%;
      }
    }
  }
}