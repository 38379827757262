.foto-card {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin: 15px 0;

  p, time {
    color: #999999;
  }

  p {
    font-size: 16px;
  }
  
  time {
    font-size: 12px;
    text-transform: uppercase;
  }

  img {
    height: auto !important;
    width: 100% !important;
  }

  h2 {
    font-size: 18px;
    text-transform: uppercase;
    color: #35bdd0;
    font-weight: 700;
    margin: 16px 0 20px 0;
  }

  a { 
    padding: 16px 26px;
    border: 1px solid #35bdd0;
    color: #35bdd0;
    border-radius: 26px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    transition: all ease 200ms;
    margin-top: 10px;
    display: inline-block;
    
    &:hover {
      color: white;
      background-color: #35bdd0;
      transition: all ease 200ms;
    }
  }
}

.pagination > li:first-child > a, .pagination > li:first-child > span {
  border-bottom-left-radius: 16px;
  border-top-left-radius: 16px;
}

.pagination > li:last-child > a {
  border-bottom-right-radius: 16px;
  border-top-right-radius: 16px;
}

.pagination > .active > a {
  color: #fff;
  background-color: #35bdd0;
  border-color: #35bdd0;
}

.pagination > li > a {
    color: #35bdd0;
    border: 1px solid #35bdd0;
}

.inner-fotos {
  color: #387e96;

  h2 {
    color: #e0af00;
    margin: 15px 0;
    font-style: italic;
  }
}

.gallery {
  img {
    margin: 30px 0;
    width: 100%;
  }
}

.load-more {
  padding: 16px 26px;
  border: 1px solid #35bdd0;
  color: #35bdd0;
  border-radius: 26px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  transition: all ease 200ms;
  margin-top: 10px;
  display: inline-block;
  
  &:hover {
    color: white;
    background-color: #35bdd0;
    transition: all ease 200ms;
  }
}

.related-title {
  font-style: italic;
  font-size: 32px;
  color: #999999;
  margin-top: 30px;
}

.see-more-card {
  margin: 30px 0;
  time {
    font-size: 12px;
    text-transform: uppercase;
    margin: 15px 0;
    color: #999999;
    display: inline-block;
  }

  img {
    width: 85%;
  }

  h3 {
    font-size: 18px;
    text-transform: uppercase;
    color: #35bdd0;
    font-weight: 700;
  }
}

.load-more-pictures {
  margin-top: 15px;
}

@media (max-width: 992px) {
  .foto-card {
    flex-direction: column;
    img {
      margin: 15px 0; 
      width: 100% !important;
    }
    
  }
}