// Slider

.slider{
	.item-slider{
		height: 660px;
		background-position: center center;
		background-size: cover;

		.botao-padrao{
			margin-top: 397px; 
			position: relative;
			z-index: 3;
		}
	}
}

.detalhe-lateral{
	position: absolute;
	left: 0;
	top: 0;
	background: url(../../assets/img/slider/detalhe-header.png) left top no-repeat;
	width: 810px;
	height: 804px;
	z-index: 5;
}


// Content

.content{
	padding-top: 230px;
	margin-top: -180px;
	position: relative;
	z-index: 1;

	.botao-padrao{
		color: $amarelo;
		border-color: $amarelo;
		margin-left: 10px;
		margin-right: 10px;

		&:hover{
			border-color: $rosa;
		}
	}
}

.box-titulo{
	text-align: center;
	margin-bottom: 40px;
	margin-top: 80px;
	.titulo{
		margin-bottom: 15px;
		font-size: 50px;
		font-style: italic;
		color: $amarelo;

		strong{
			display: inline-block;
			background: url(../../assets/img/detalhe-titulos.png) bottom center repeat-x;
			padding-bottom: 20px;
		}
	}
}

.pequeno-texto{
	text-align: center;
	p{
		font-size: 18px;
		color: $azul;
	}
}

.passos{
	h3{
		font-weight: 400;
		font-style: italic;
		color: $amarelo;
		font-size: 30px;
		margin-bottom: 12px;
	}

	.pequeno-texto{
		margin-bottom: 60px;
		p{ font-size: 16px; }
	}
}
.numeracao{
	border: solid 1px $amarelo;
	width: 100px;
	height: 100px;
	display: inline-block;
	@include radius(50%);
	padding-top: 14px;
	margin-bottom: 10px;
}

.numero{
	width: 70px;
	height: 70px;
	display: inline-block;
	background: $amarelo;
	line-height: 70px;
	text-align: center;
	color: $branco;
	font-size: 50px;
	font-family: $mont;
	font-weight: 700;
	@include radius(50%);
}

// Galeria

.box-galeria{
	background: url(../../assets/img/bg-fotos.png) top center no-repeat;
	padding-top: 85px;
    padding-bottom: 85px;    
    background-size: cover;
	margin-top: 60px;

	.box-titulo{
			margin-top: 0px;
		.titulo	{
			color: $branco; 
		}
	}
}

.galeria{
	.slick-dots{
		width: 100%;
		text-align: center;
		position: relative;
		z-index: 1000;
		margin-top: 20px;
		margin-bottom: 15px;
		li{
			display: inline-block;
			margin-left: 10px;
			margin-right: 10px;
			text-indent: -9999px;
			background: $branco;
			width: 10px;
			height: 10px;
			cursor: pointer;
			&.slick-active{
				background: $rosa;
			}
		}
	}
}

.item-galeria{
	display: inline-block;
}


.botao-padrao{
	display: inline-block;
	border: solid 1px $branco;
	text-transform: uppercase;
	font-size: 12px;
	padding-left: 15px;
	padding-right: 15px;
	padding-top: 15px;
	padding-bottom: 15px;
	font-weight: 500;
	font-family: $mont;
	@include transition(0.3s);
	@include radius(30px);
	color: $branco;

	&:hover{
		background: $branco;
		color: $rosa;
	}

	&.botao-ativo{
		background: $branco;
		color: $rosa;
		font-size: 12px;
		font-weight: 700;
	}

	&.botao-ativo-comprar{
		background: #dd2fa8; /* Old browsers */
		color: $branco;
		border-color: transparent;
		background: -moz-linear-gradient(left, #dd2fa8 0%, #fc438e 100%, #7db9e8 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(left, #dd2fa8 0%,#fc438e 100%,#7db9e8 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to right, #dd2fa8 0%,#fc438e 100%,#7db9e8 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#dd2fa8', endColorstr='#7db9e8',GradientType=1 ); /* IE6-9 */
	}
}


// Sobre

.box-header-interna{
	height: 466px;
	background-image: url(../img/slide.png), url(../img/bg-header-interna.png);
	background-position: 4px 90px, 2px -4px;
	background-repeat: repeat-x;
	position: relative;
	width: 100%;
	margin-bottom: -390px;
}

.detalhe-interna{
	background: url(../img/detalhe-menor.png) center center no-repeat;
	height: 290px;
	width: 587px;
	position: absolute;
	left: 105px;
	top: 0;
}

.sobre-parasail{
  margin-top: 55px;
  h2 {
    color: #e0af00;
    font-weight: 700;
    font-style: italic;
    margin-bottom: 20px;
  }
	p{
		font-size: 16px;
		color: $azul;
    margin-bottom: 40px;
    font-style: italic;
	}
}

.botao-comprar-sidebar{
	display: inline-block;
	margin-top: 55px;
}

.--how-works {
  margin-top: 15px;
}

#map {
	iframe {
		width: 100%;
	}
}