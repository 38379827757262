header{ 
	padding-top: 15px; 
	position: absolute;
	top: 0; left: 0;
	z-index: 10;
    width: 100%;
    background-image: linear-gradient(to bottom, rgba(47,85,122,0.9), rgba(47,85,122,0));
}

.logo{
	a{
		display: block;
		width: 100%;
		img{
			width: 100%;
			max-width: 202px;
		}
	}
}

.ms{
	text-align: right;
	margin-bottom: 20px;
	li{
		display: inline-block;
		margin-left: 5px;
		margin-right: 5px;
		a{
			width: 25px;
			height: 25px;
			@include transition(0.3s);
			@include radius(50%);
			text-align: center;
			line-height: 25px;
			display: inline-block;
			i{ color: $branco; }

			&.facebook{ background: #4e71a8; }
			&.instagram{ background: #444444; }
			&.tripadvisor{ background: #00a500; }
		}
	}
}

.menu{
	text-align: right;
	li{
		margin-left: 5px;
		margin-right: 5px;
		display: inline-block;
		a{
			display: inline-block;
			border: solid 1px $branco;
			text-transform: uppercase;
			font-size: 12px;
			padding-left: 15px;
			padding-right: 15px;
			padding-top: 15px;
			padding-bottom: 15px;
			font-weight: 500;
			font-family: $mont;
			@include transition(0.3s);
			@include radius(30px);
			color: $branco;

			&:hover{
				background: $branco;
				color: $rosa;
			}

			&.comprar-ativo{
				background: $branco;
				color: $rosa;
				font-size: 14px;
				font-weight: 700;
			}
		}
	}
}

.dropdown-content {
  position: absolute;
  flex-direction: column;
  align-items: center;
  display: none;
  li {
    padding: 5px 0;
  }
}

.dropdown:hover .dropdown-content {
  display: flex;
}

.box-slider {
    position: relative;
	&::after {
		content: '';
        background: url('../img/slide.png');
        position: absolute;
        width: 100%;
        left: 0;
        height: 380px;
        z-index: 0;
        bottom: -180px;
	}
}

.video { 
	position: relative; 
	padding-bottom: 56.25%; /* 16:9 */  
	height: 100%; 
	overflow: hidden; 

	img { 
		position: absolute; 
		display: block; 
		top: 0; 
		left: 0; 
		width: 100%; 
		height: 100%;
		z-index: 20; 
		cursor: pointer; 
	}

	iframe { 
		position: absolute; 
		top: 0; 
		left: 0; 
		width: 160%;
    height: 160%;
		
	}

	&:after { 
		content: ""; 
		position: absolute; 
		display: block; 
    background: url(play-button.png) no-repeat 0 0; 
		top: 45%; 
		left: 45%; 
		width: 46px; 
		height: 36px; 
		z-index: 30; 
		cursor: pointer; 
	} 

	&.player img { 
		display: none; 
	}

	&.player:after { 
		display: none; 
	}
}

@media (min-width: 768px) {
	.video {
		iframe {
			transform: translate(-19%, -22%);
		}
	}
}


@media (min-width: 1920px) {
	.video {
		iframe {
			transform: translate(-18%, -29%);
		}
	}
}


.slick-dots {
	position: absolute;
	z-index: 10;
	display: flex;
	right: 50%;
	transform: translateX(-50%);	
	li button {
		background: #e7c300;
    border: none;
    color: #e7c300;
    border-radius: 30px;
    margin: 0px 5px;
    width: 16px;
		height: 16px;
		opacity: 0.6;
		box-shadow: 1px 1px 6px rgba(0,0,0, 0.5);
	}
	.slick-active button {
		opacity: 1;
	}
}