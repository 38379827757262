.video-card {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin: 15px 0;

  &::before {
    content: "\f04b";
    font: normal normal normal 14px/1 FontAwesome;
    background-color: rgba(255, 255, 255, 0.6);
    position: absolute;
    z-index: 1;
    padding: 25px 29px;
    border-radius: 100%;
    font-size: 2vw;
    left: 22%;
    top: 25%;
  }

  p, time {
    color: #999999;
  }

  p {
    font-size: 16px;
  }
  
  time {
    font-size: 12px;
    text-transform: uppercase;
  }

  img {
    margin-right: 30px;
    height: 100% !important;
    width: auto !important;    
  }

  h2 {
    font-size: 18px;
    text-transform: uppercase;
    color: #35bdd0;
    font-weight: 700;
    margin: 16px 0 20px 0;
  }

}

@media (max-width: 1200px) {
  .video-card {
    &::before {
      top: 12%;
      left: 27%;
    }
  }
}

@media (max-width: 992px) {
  .video-card {
    flex-direction: column;

    &::before {
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 4vw;
    }

    img {
      margin: 15px 0; 
      width: 100% !important;
    }
  }
}

@media (max-width: 425px) {
  .video-card {

    &::before {
      top: 33%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 4vw;
    }

  }
}