.panel-body {
  border-radius: 0px 0px 6px 6px;
  border: 1px solid #bababa;
  color: #999999;
  border-top: none;
  display: none;
  padding: 25px 15px 15px 15px;
  margin-top: -10px;
  font-style: italic;
}

.accordion {
  border: none;
  border-bottom: 1px solid #36bdd0;
  border-radius: 0px;
  display: block;
  padding: 15px;
  color: #36bdd0;
  margin-top: 15px; 
  font-style: italic;
  &:hover, &:active, &:focus {
    color: #36bdd0;
  }
  &::after {
    content: "\f055";
    font: normal normal normal 14px/1 FontAwesome;
    position: absolute;
    right: 30px;
  }
}

.accordion.active {
  color: white;
  background-color: #36bdd0;
  padding: 15px;
  border-radius: 16px;
  &::after {
    content: '\f056';
  }
}