.how-works {
  color: #387e96;

  h2, h3 {
    color: #e0af00;
    margin: 15px 0;
    font-style: italic;
  }

  h2 {
    font-weight: 700;
  }

  p {
    font-style: italic;
  }

  .list ul li {
    padding-left: 18px;
    position: relative;
    font-style: italic;

    &::before{
      content: '';
      width: 16px;
      height: 16px;
      background: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxMjkgMTI5IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAxMjkgMTI5IiB3aWR0aD0iMTZweCIgaGVpZ2h0PSIxNnB4Ij4KICA8Zz4KICAgIDxwYXRoIGQ9Im00MC40LDEyMS4zYy0wLjgsMC44LTEuOCwxLjItMi45LDEuMnMtMi4xLTAuNC0yLjktMS4yYy0xLjYtMS42LTEuNi00LjIgMC01LjhsNTEtNTEtNTEtNTFjLTEuNi0xLjYtMS42LTQuMiAwLTUuOCAxLjYtMS42IDQuMi0xLjYgNS44LDBsNTMuOSw1My45YzEuNiwxLjYgMS42LDQuMiAwLDUuOGwtNTMuOSw1My45eiIgZmlsbD0iIzFhNzI4ZCIvPgogIDwvZz4KPC9zdmc+Cg==);                                             
      position: absolute;
      left: 0px;
    }
  }

  .included {
    display: flex;
    flex-wrap: wrap;
    .list {
      margin-right: 45px;
    }
  }
}