footer{
	background: url(../../assets/img/bg-footer.png) bottom center repeat-x;
	padding-top: 104px;
}

.infos-de-contato{
	span{
		display: block;
		margin-bottom: 25px;
		color: $azul;
		font-size: 14px;
		font-family: $mont;
		font-weight: 500;
		i{
			color: $rosa;
			margin-right: 5px;
		}
	}
	.ms {
		text-align: left;
	}
}

#map{
	height: 260px;
	margin-bottom: 30px;
}

.ass{ margin-bottom: 30px; }