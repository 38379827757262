/*===========================================
	VARIAVEIS
============================================*/

@import 'variaveis';

/*===========================================
	BASE
============================================*/

@import 'base';

/*===========================================
	HEADER
============================================*/

@import 'header';

/*===========================================
	CONTENT
============================================*/

@import 'content';

/*===========================================
	FOOTER
============================================*/

@import 'footer';


/*===========================================
	BOOTSTRAP
============================================*/

@import 'bootstrap';

/*===========================================
	RESPONSIVO
============================================*/

@import 'mobile';

@import '_duvidas.scss';

@import '_how-works.scss';

@import '_contact.scss';

@import '_foto-card.scss';

@import '_video-card.scss';

@import '_localizacao.scss';