.no-padding{
	padding:{
		left: 0;
		right: 0;
	};
	.row{
		margin:{
			left: 0;
			right: 0;
		};
		[classˆ="col-"], [class*="col-"]{
			padding:{
				left: 0;
				right: 0;
			};
		}
	}
}

// Class Halpers