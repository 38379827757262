// Cores
$branco: #fff;
$preto: #000;

$rosa: #fc438e;
$azul: #1a728d;
$amarelo: #e0af00;

// Fonts
$lora: 'Lora', serif;
$mont: 'Montserrat', sans-serif;

// Mixins
@mixin radius ($radius){
	-moz-border-radius: $radius;
	-webkit-border-radius: $radius;
	border-radius: $radius;
}

@mixin transition ($all){
	-webkit-transition: all ($all) ;
    -moz-transition: all ($all) ;
    -o-transition: all ($all) ;
    transition: all ($all);
}


@mixin box-shadow ($style){
	-moz-box-shadow: ($style);
	-webkit-box-shadow: ($style);
	box-shadow: ($style);
}