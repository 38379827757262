body{
	font:{
		size: 14px;
		weight: 400;
		family: $lora;
	};
}

h1, h2, h3, h4, h5{margin: 0px; font-weight: inherit;}

ul{
	padding: 0;
	margin: 0;
	li{
		list-style: none;
	}
}

a{
	text-decoration: none;
	color: inherit;
	&:hover{
		text-decoration: none;
		color: inherit;
	}
	&:active{
		text-decoration: none;
		color: inherit;
	}
	&:focus{
		outline: none;
		text-decoration: none;
		color: inherit;
	}
}

// Gerais

// Gradient

/* -moz-linear-gradient(left, #074fa0 35%, #0976a0 100%); 
-webkit-linear-gradient(left, #074fa0 35%,#0976a0 100%); 
linear-gradient(to right, #074fa0 35%,#0976a0 100%); */

h1{ font-size: 32px; }
h2{ font-size: 24px; }
h3{ font-size: 18px; }

.selecao-texto::selection{ background: $azul; color: $branco; } 