@charset "UTF-8";
/*===========================================
	VARIAVEIS
============================================*/
/*===========================================
	BASE
============================================*/
body {
  font-size: 14px;
  font-weight: 400;
  font-family: "Lora", serif; }

h1, h2, h3, h4, h5 {
  margin: 0px;
  font-weight: inherit; }

ul {
  padding: 0;
  margin: 0; }
  ul li {
    list-style: none; }

a {
  text-decoration: none;
  color: inherit; }
  a:hover {
    text-decoration: none;
    color: inherit; }
  a:active {
    text-decoration: none;
    color: inherit; }
  a:focus {
    outline: none;
    text-decoration: none;
    color: inherit; }

/* -moz-linear-gradient(left, #074fa0 35%, #0976a0 100%); 
-webkit-linear-gradient(left, #074fa0 35%,#0976a0 100%); 
linear-gradient(to right, #074fa0 35%,#0976a0 100%); */
h1 {
  font-size: 32px; }

h2 {
  font-size: 24px; }

h3 {
  font-size: 18px; }

.selecao-texto::selection {
  background: #1a728d;
  color: #fff; }

/*===========================================
	HEADER
============================================*/
header {
  padding-top: 15px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  background-image: linear-gradient(to bottom, rgba(47, 85, 122, 0.9), rgba(47, 85, 122, 0)); }

.logo a {
  display: block;
  width: 100%; }
  .logo a img {
    width: 100%;
    max-width: 202px; }

.ms {
  text-align: right;
  margin-bottom: 20px; }
  .ms li {
    display: inline-block;
    margin-left: 5px;
    margin-right: 5px; }
    .ms li a {
      width: 25px;
      height: 25px;
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      -o-transition: all 0.3s;
      transition: all 0.3s;
      -moz-border-radius: 50%;
      -webkit-border-radius: 50%;
      border-radius: 50%;
      text-align: center;
      line-height: 25px;
      display: inline-block; }
      .ms li a i {
        color: #fff; }
      .ms li a.facebook {
        background: #4e71a8; }
      .ms li a.instagram {
        background: #444444; }
      .ms li a.tripadvisor {
        background: #00a500; }

.menu {
  text-align: right; }
  .menu li {
    margin-left: 5px;
    margin-right: 5px;
    display: inline-block; }
    .menu li a {
      display: inline-block;
      border: solid 1px #fff;
      text-transform: uppercase;
      font-size: 12px;
      padding-left: 15px;
      padding-right: 15px;
      padding-top: 15px;
      padding-bottom: 15px;
      font-weight: 500;
      font-family: "Montserrat", sans-serif;
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      -o-transition: all 0.3s;
      transition: all 0.3s;
      -moz-border-radius: 30px;
      -webkit-border-radius: 30px;
      border-radius: 30px;
      color: #fff; }
      .menu li a:hover {
        background: #fff;
        color: #fc438e; }
      .menu li a.comprar-ativo {
        background: #fff;
        color: #fc438e;
        font-size: 14px;
        font-weight: 700; }

.dropdown-content {
  position: absolute;
  flex-direction: column;
  align-items: center;
  display: none; }
  .dropdown-content li {
    padding: 5px 0; }

.dropdown:hover .dropdown-content {
  display: flex; }

.box-slider {
  position: relative; }
  .box-slider::after {
    content: '';
    background: url("../img/slide.png");
    position: absolute;
    width: 100%;
    left: 0;
    height: 380px;
    z-index: 0;
    bottom: -180px; }

.video {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  height: 100%;
  overflow: hidden; }
  .video img {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 20;
    cursor: pointer; }
  .video iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 160%;
    height: 160%; }
  .video:after {
    content: "";
    position: absolute;
    display: block;
    background: url(play-button.png) no-repeat 0 0;
    top: 45%;
    left: 45%;
    width: 46px;
    height: 36px;
    z-index: 30;
    cursor: pointer; }
  .video.player img {
    display: none; }
  .video.player:after {
    display: none; }

@media (min-width: 768px) {
  .video iframe {
    transform: translate(-19%, -22%); } }

@media (min-width: 1920px) {
  .video iframe {
    transform: translate(-18%, -29%); } }

.slick-dots {
  position: absolute;
  z-index: 10;
  display: flex;
  right: 50%;
  transform: translateX(-50%); }
  .slick-dots li button {
    background: #e7c300;
    border: none;
    color: #e7c300;
    border-radius: 30px;
    margin: 0px 5px;
    width: 16px;
    height: 16px;
    opacity: 0.6;
    box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.5); }
  .slick-dots .slick-active button {
    opacity: 1; }

/*===========================================
	CONTENT
============================================*/
.slider .item-slider {
  height: 660px;
  background-position: center center;
  background-size: cover; }
  .slider .item-slider .botao-padrao {
    margin-top: 397px;
    position: relative;
    z-index: 3; }

.detalhe-lateral {
  position: absolute;
  left: 0;
  top: 0;
  background: url(../../assets/img/slider/detalhe-header.png) left top no-repeat;
  width: 810px;
  height: 804px;
  z-index: 5; }

.content {
  padding-top: 230px;
  margin-top: -180px;
  position: relative;
  z-index: 1; }
  .content .botao-padrao {
    color: #e0af00;
    border-color: #e0af00;
    margin-left: 10px;
    margin-right: 10px; }
    .content .botao-padrao:hover {
      border-color: #fc438e; }

.box-titulo {
  text-align: center;
  margin-bottom: 40px;
  margin-top: 80px; }
  .box-titulo .titulo {
    margin-bottom: 15px;
    font-size: 50px;
    font-style: italic;
    color: #e0af00; }
    .box-titulo .titulo strong {
      display: inline-block;
      background: url(../../assets/img/detalhe-titulos.png) bottom center repeat-x;
      padding-bottom: 20px; }

.pequeno-texto {
  text-align: center; }
  .pequeno-texto p {
    font-size: 18px;
    color: #1a728d; }

.passos h3 {
  font-weight: 400;
  font-style: italic;
  color: #e0af00;
  font-size: 30px;
  margin-bottom: 12px; }

.passos .pequeno-texto {
  margin-bottom: 60px; }
  .passos .pequeno-texto p {
    font-size: 16px; }

.numeracao {
  border: solid 1px #e0af00;
  width: 100px;
  height: 100px;
  display: inline-block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  padding-top: 14px;
  margin-bottom: 10px; }

.numero {
  width: 70px;
  height: 70px;
  display: inline-block;
  background: #e0af00;
  line-height: 70px;
  text-align: center;
  color: #fff;
  font-size: 50px;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%; }

.box-galeria {
  background: url(../../assets/img/bg-fotos.png) top center no-repeat;
  padding-top: 85px;
  padding-bottom: 85px;
  background-size: cover;
  margin-top: 60px; }
  .box-galeria .box-titulo {
    margin-top: 0px; }
    .box-galeria .box-titulo .titulo {
      color: #fff; }

.galeria .slick-dots {
  width: 100%;
  text-align: center;
  position: relative;
  z-index: 1000;
  margin-top: 20px;
  margin-bottom: 15px; }
  .galeria .slick-dots li {
    display: inline-block;
    margin-left: 10px;
    margin-right: 10px;
    text-indent: -9999px;
    background: #fff;
    width: 10px;
    height: 10px;
    cursor: pointer; }
    .galeria .slick-dots li.slick-active {
      background: #fc438e; }

.item-galeria {
  display: inline-block; }

.botao-padrao {
  display: inline-block;
  border: solid 1px #fff;
  text-transform: uppercase;
  font-size: 12px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  -moz-border-radius: 30px;
  -webkit-border-radius: 30px;
  border-radius: 30px;
  color: #fff; }
  .botao-padrao:hover {
    background: #fff;
    color: #fc438e; }
  .botao-padrao.botao-ativo {
    background: #fff;
    color: #fc438e;
    font-size: 12px;
    font-weight: 700; }
  .botao-padrao.botao-ativo-comprar {
    background: #dd2fa8;
    /* Old browsers */
    color: #fff;
    border-color: transparent;
    background: -moz-linear-gradient(left, #dd2fa8 0%, #fc438e 100%, #7db9e8 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #dd2fa8 0%, #fc438e 100%, #7db9e8 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, #dd2fa8 0%, #fc438e 100%, #7db9e8 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#dd2fa8', endColorstr='#7db9e8',GradientType=1 );
    /* IE6-9 */ }

.box-header-interna {
  height: 466px;
  background-image: url(../img/slide.png), url(../img/bg-header-interna.png);
  background-position: 4px 90px, 2px -4px;
  background-repeat: repeat-x;
  position: relative;
  width: 100%;
  margin-bottom: -390px; }

.detalhe-interna {
  background: url(../img/detalhe-menor.png) center center no-repeat;
  height: 290px;
  width: 587px;
  position: absolute;
  left: 105px;
  top: 0; }

.sobre-parasail {
  margin-top: 55px; }
  .sobre-parasail h2 {
    color: #e0af00;
    font-weight: 700;
    font-style: italic;
    margin-bottom: 20px; }
  .sobre-parasail p {
    font-size: 16px;
    color: #1a728d;
    margin-bottom: 40px;
    font-style: italic; }

.botao-comprar-sidebar {
  display: inline-block;
  margin-top: 55px; }

.--how-works {
  margin-top: 15px; }

#map iframe {
  width: 100%; }

/*===========================================
	FOOTER
============================================*/
footer {
  background: url(../../assets/img/bg-footer.png) bottom center repeat-x;
  padding-top: 104px; }

.infos-de-contato span {
  display: block;
  margin-bottom: 25px;
  color: #1a728d;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500; }
  .infos-de-contato span i {
    color: #fc438e;
    margin-right: 5px; }

.infos-de-contato .ms {
  text-align: left; }

#map {
  height: 260px;
  margin-bottom: 30px; }

.ass {
  margin-bottom: 30px; }

/*===========================================
	BOOTSTRAP
============================================*/
.no-padding {
  padding-left: 0;
  padding-right: 0; }
  .no-padding .row {
    margin-left: 0;
    margin-right: 0; }
    .no-padding .row [classˆ="col-"], .no-padding .row [class*="col-"] {
      padding-left: 0;
      padding-right: 0; }

/*===========================================
	RESPONSIVO
============================================*/
/* 320px */
.detalhe-lateral,
.detalhe-interna {
  display: none; }

.botao-padrao {
  margin-bottom: 30px; }

.item-galeria {
  text-align: center;
  margin-bottom: 25px; }
  .item-galeria a img {
    display: inline-block; }

.box-galeria {
  padding-bottom: 0; }

.logo-footer {
  text-align: center;
  margin-bottom: 30px; }

.infos-de-contato {
  text-align: center; }
  .infos-de-contato .ms {
    text-align: inherit; }

.clique-menu {
  color: #fff;
  width: 50px;
  height: 50px;
  border: #fff solid 3px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  text-align: center;
  line-height: 45px;
  font-size: 20px;
  display: inline-block; }

.menu-responsivo {
  display: none;
  position: absolute;
  padding-top: 20px;
  padding-bottom: 50px;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 9000;
  background: rgba(255, 69, 140, 0.8); }
  .menu-responsivo .ms {
    text-align: center; }

.mobile-menu {
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  font-size: 700;
  color: #fff;
  margin-bottom: 30px; }
  .mobile-menu li {
    margin-bottom: 10px; }
    .mobile-menu li a {
      font-size: 18px; }
  .mobile-menu .buy-now {
    margin: 30px 0; }
    .mobile-menu .buy-now a {
      border-radius: 50px;
      color: #ff458c;
      background-color: white;
      padding: 15px; }

.fechar-menu {
  color: #fff;
  margin-bottom: 20px;
  font-size: 30px; }

.detalhe-lateral-mobile {
  background: url(../../assets/img/detalhe-menor.png) left top no-repeat;
  position: absolute;
  width: 587px;
  height: 290px;
  top: 0;
  left: -340px;
  z-index: 1; }

.box-galeria {
  padding-top: 30px; }

@media screen and (min-width: 540px) {
  .detalhe-lateral-mobile {
    left: -225px; } }

@media screen and (min-width: 768px) {
  .detalhe-lateral-mobile {
    left: -265px; } }

@media screen and (min-width: 992px) {
  .detalhe-lateral-mobile {
    left: -280px; }
  .box-galeria {
    padding-top: 85px; } }

@media screen and (min-width: 1200px) {
  .detalhe-lateral-mobile {
    display: none; }
  .detalhe-lateral,
  .detalhe-interna {
    display: block; }
  .detalhe-lateral {
    left: -340px; }
  .detalhe-interna {
    left: -245px; }
  .item-galeria {
    margin-bottom: 0px; }
  .botao-padrao {
    margin-bottom: 0px; }
  .box-galeria {
    padding-bottom: 85px; }
  .logo-footer {
    text-align: left;
    margin-bottom: 0px; }
  .infos-de-contato {
    text-align: left; }
    .infos-de-contato .ms {
      text-align: inherit; } }

@media screen and (min-width: 1400px) {
  .detalhe-lateral {
    left: -255px; }
  .detalhe-interna {
    left: -145px; } }

@media screen and (min-width: 1600px) {
  .detalhe-lateral {
    left: -165px; }
  .detalhe-interna {
    left: -50px; } }

@media screen and (min-width: 1700px) {
  .detalhe-lateral {
    left: -115px; }
  .detalhe-interna {
    left: 0px; } }

@media screen and (min-width: 1800px) {
  .detalhe-lateral {
    left: 0px; }
  .detalhe-interna {
    left: 100px; } }

.panel-body {
  border-radius: 0px 0px 6px 6px;
  border: 1px solid #bababa;
  color: #999999;
  border-top: none;
  display: none;
  padding: 25px 15px 15px 15px;
  margin-top: -10px;
  font-style: italic; }

.accordion {
  border: none;
  border-bottom: 1px solid #36bdd0;
  border-radius: 0px;
  display: block;
  padding: 15px;
  color: #36bdd0;
  margin-top: 15px;
  font-style: italic; }
  .accordion:hover, .accordion:active, .accordion:focus {
    color: #36bdd0; }
  .accordion::after {
    content: "\f055";
    font: normal normal normal 14px/1 FontAwesome;
    position: absolute;
    right: 30px; }

.accordion.active {
  color: white;
  background-color: #36bdd0;
  padding: 15px;
  border-radius: 16px; }
  .accordion.active::after {
    content: '\f056'; }

.how-works {
  color: #387e96; }
  .how-works h2, .how-works h3 {
    color: #e0af00;
    margin: 15px 0;
    font-style: italic; }
  .how-works h2 {
    font-weight: 700; }
  .how-works p {
    font-style: italic; }
  .how-works .list ul li {
    padding-left: 18px;
    position: relative;
    font-style: italic; }
    .how-works .list ul li::before {
      content: '';
      width: 16px;
      height: 16px;
      background: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxMjkgMTI5IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAxMjkgMTI5IiB3aWR0aD0iMTZweCIgaGVpZ2h0PSIxNnB4Ij4KICA8Zz4KICAgIDxwYXRoIGQ9Im00MC40LDEyMS4zYy0wLjgsMC44LTEuOCwxLjItMi45LDEuMnMtMi4xLTAuNC0yLjktMS4yYy0xLjYtMS42LTEuNi00LjIgMC01LjhsNTEtNTEtNTEtNTFjLTEuNi0xLjYtMS42LTQuMiAwLTUuOCAxLjYtMS42IDQuMi0xLjYgNS44LDBsNTMuOSw1My45YzEuNiwxLjYgMS42LDQuMiAwLDUuOGwtNTMuOSw1My45eiIgZmlsbD0iIzFhNzI4ZCIvPgogIDwvZz4KPC9zdmc+Cg==);
      position: absolute;
      left: 0px; }
  .how-works .included {
    display: flex;
    flex-wrap: wrap; }
    .how-works .included .list {
      margin-right: 45px; }

.call-to-form {
  margin: 30px 0;
  color: #36bdd0;
  font-weight: 700;
  font-style: italic; }

.contact input {
  font-style: italic;
  padding: 15px;
  border-radius: 12px;
  box-shadow: none;
  border: 1px solid #999999;
  margin: 5px 5px; }

.contact textarea {
  border-radius: 12px;
  resize: none;
  height: 100%;
  width: 100%;
  padding: 15px;
  margin: 5px;
  font-style: italic; }

.contact .form-cols {
  display: flex;
  justify-content: space-around;
  align-items: stretch;
  flex-direction: row; }
  .contact .form-cols > * {
    width: 50%; }
  .contact .form-cols .inputs {
    display: flex;
    flex-direction: column; }

.contact .sent {
  font-weight: 700;
  color: white;
  background-color: #36bdd0;
  border: none;
  border-radius: 22px;
  padding: 12px 50px;
  margin-top: 15px; }

@media (max-width: 475px) {
  .contact textarea, .contact input {
    margin: 5px 0; }
  .contact .form-cols {
    flex-direction: column; }
    .contact .form-cols > * {
      width: 100%; } }

.foto-card {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin: 15px 0; }
  .foto-card p, .foto-card time {
    color: #999999; }
  .foto-card p {
    font-size: 16px; }
  .foto-card time {
    font-size: 12px;
    text-transform: uppercase; }
  .foto-card img {
    height: auto !important;
    width: 100% !important; }
  .foto-card h2 {
    font-size: 18px;
    text-transform: uppercase;
    color: #35bdd0;
    font-weight: 700;
    margin: 16px 0 20px 0; }
  .foto-card a {
    padding: 16px 26px;
    border: 1px solid #35bdd0;
    color: #35bdd0;
    border-radius: 26px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    transition: all ease 200ms;
    margin-top: 10px;
    display: inline-block; }
    .foto-card a:hover {
      color: white;
      background-color: #35bdd0;
      transition: all ease 200ms; }

.pagination > li:first-child > a, .pagination > li:first-child > span {
  border-bottom-left-radius: 16px;
  border-top-left-radius: 16px; }

.pagination > li:last-child > a {
  border-bottom-right-radius: 16px;
  border-top-right-radius: 16px; }

.pagination > .active > a {
  color: #fff;
  background-color: #35bdd0;
  border-color: #35bdd0; }

.pagination > li > a {
  color: #35bdd0;
  border: 1px solid #35bdd0; }

.inner-fotos {
  color: #387e96; }
  .inner-fotos h2 {
    color: #e0af00;
    margin: 15px 0;
    font-style: italic; }

.gallery img {
  margin: 30px 0;
  width: 100%; }

.load-more {
  padding: 16px 26px;
  border: 1px solid #35bdd0;
  color: #35bdd0;
  border-radius: 26px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  transition: all ease 200ms;
  margin-top: 10px;
  display: inline-block; }
  .load-more:hover {
    color: white;
    background-color: #35bdd0;
    transition: all ease 200ms; }

.related-title {
  font-style: italic;
  font-size: 32px;
  color: #999999;
  margin-top: 30px; }

.see-more-card {
  margin: 30px 0; }
  .see-more-card time {
    font-size: 12px;
    text-transform: uppercase;
    margin: 15px 0;
    color: #999999;
    display: inline-block; }
  .see-more-card img {
    width: 85%; }
  .see-more-card h3 {
    font-size: 18px;
    text-transform: uppercase;
    color: #35bdd0;
    font-weight: 700; }

.load-more-pictures {
  margin-top: 15px; }

@media (max-width: 992px) {
  .foto-card {
    flex-direction: column; }
    .foto-card img {
      margin: 15px 0;
      width: 100% !important; } }

.video-card {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin: 15px 0; }
  .video-card::before {
    content: "\f04b";
    font: normal normal normal 14px/1 FontAwesome;
    background-color: rgba(255, 255, 255, 0.6);
    position: absolute;
    z-index: 1;
    padding: 25px 29px;
    border-radius: 100%;
    font-size: 2vw;
    left: 22%;
    top: 25%; }
  .video-card p, .video-card time {
    color: #999999; }
  .video-card p {
    font-size: 16px; }
  .video-card time {
    font-size: 12px;
    text-transform: uppercase; }
  .video-card img {
    margin-right: 30px;
    height: 100% !important;
    width: auto !important; }
  .video-card h2 {
    font-size: 18px;
    text-transform: uppercase;
    color: #35bdd0;
    font-weight: 700;
    margin: 16px 0 20px 0; }

@media (max-width: 1200px) {
  .video-card::before {
    top: 12%;
    left: 27%; } }

@media (max-width: 992px) {
  .video-card {
    flex-direction: column; }
    .video-card::before {
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 4vw; }
    .video-card img {
      margin: 15px 0;
      width: 100% !important; } }

@media (max-width: 425px) {
  .video-card::before {
    top: 33%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 4vw; } }

.location {
  color: #367d96; }
  .location span {
    margin: 15px 0;
    display: inline-block; }
